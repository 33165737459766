import Turbolinks from "turbolinks";

const App = {
  REGEX_FOR_PHONE_NUMBER: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
  REGEX_FOR_EMAIL: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  REGEX_FOR_NUMBERS: /^[0-9.]*$/,
  REGEX_FOR_STRINGS: /^[a-zA-Z0-9\s.!#$%&'*+/=?^_`{|}~-]*$/,
  
  sayHello(){
    console.log("Hello");
  },

  scrollToTop: function () {
    document.documentElement.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  },

  checkIfScrollToTopMustBeShowed: function() {
    // Do something on scroll
    if ((document.documentElement.scrollTop >  document.documentElement.clientHeight ) ) {
      // Show button
      $("#scroll-to-top-button").addClass("show-button")
    } else {
      // Hide button
      $("#scroll-to-top-button").removeClass("show-button")
    }
  },
  
  initEverything(){
    // $(window).scroll(() => {
    //   App.updateParallaxBGPositions();
    // });
    // App.setParallaxBGPositions();
    // App.updateParallaxBGPositions();
  },

  setParallaxBGPositions(){
    $(".parallax-bg").each((idx, obj)=>{
      $(obj).css("background-position", `50% 0px`);
    });
  },

  updateParallaxBGPositions(){
    let windowScrollTop = $(window).scrollTop();
    $(".parallax-bg").each((idx, obj)=>{
      let scale = $(obj).width() / $(obj).attr("data-img-w");
      let factor = 1 - (1 - (windowScrollTop / $(obj).offset().top));
      let delta = ($(obj).attr("data-img-h") * scale) / $(obj).attr("data-y-factor");
      let newYPos = $(obj).attr("data-y-offset") - (factor * delta);
      $(obj).css("background-position", `50% ${newYPos}px`);
    });
  },

  viewNewsItem(newsItemId){
    let url = `/admin/news/${newsItemId}`;
    Turbolinks.visit(url);
  },

  viewAchievement(id){
    let url = `/admin/achievements/${id}`;
    Turbolinks.visit(url);
  },

  viewCircular(id){
    let url = `/admin/circulars/${id}`;
    Turbolinks.visit(url);
  },

  viewUser(id){
    let url = `/admin/users/${id}`;
    Turbolinks.visit(url);
  },

  loadingMessageObject: null,
  loadingMessageCounter: 0,

  showLoadingMessage(message){
    if(message === undefined){
      message  = "Loading..."
    }
    if(loadingMessageCounter == 0){
      // loadingMessageObject = $.notify({message: message}, {allow_dismiss: false, type: "warning", delay: 15000, placement:{from: "top", align: "center"}});
      loadingMessageCounter++;
    } else if(loadingMessageCounter > 0) {
      loadingMessageCounter++;
    }
  },

  closeLoadingMessage(){
    if(loadingMessageCounter == 1){
      loadingMessageObject.close();
      loadingMessageCounter--;
    } else if(loadingMessageCounter > 1){
      loadingMessageCounter--;
    }
    $(".modal-backdrop.fade.in").removeClass("modal-backdrop fade in");
  },

  showSuccessMessage(msg){
    let options = {};
    if (msg)
    options.message = msg;

    this.showAlertModal("Success", msg);
  },

  showFailureMessage(msg){
    let options = {};
    if (msg)
    options.message = msg;
    this.showAlertModal("Alert", msg);
  },

  showAlertModal(title, message) {
    let modal =  new bootstrap.Modal("div#alert-modal.modal", {});
    document.querySelector("div#alert-modal.modal .modal-title").innerHTML = title
    document.querySelector("div#alert-modal.modal .modal-body p").innerHTML = message
    modal.show();
  },

  toggleSelectAll(classSelector, reference){
    let checkboxes = $("."+classSelector);
    let selectAll = $(".select-all");
    let toggleTo = selectAll.prop("checked")? "checked": false;
    if(reference != undefined){
      toggleTo = reference.prop("checked")? "checked": false;
    }
    for(let i=0; i<checkboxes.length; i++){
      $(checkboxes[i]).prop("checked", toggleTo);
    }
  },

  setupTimePicker(elementId, minDate=null, maxDate=null){
    let defaultDate = $("#"+elementId).val();
    let options = {
      format:"HH:mm",
      sideBySide: true,
      widgetPositioning:{
        vertical: "bottom",
        horizontal: "left"
      },
    };
    if(minDate){
      options.minDate = minDate;
    }
    if(maxDate){
      options.maxDate = maxDate;
    }
    $("#"+elementId).attr("autocomplete", "false");
    $("#"+elementId).datetimepicker(options);
    if(defaultDate!=""){
      $("#"+elementId).val(defaultDate);
    }
  },

  setupDateTimePicker(elementId, minDate=null, maxDate=null){
    let defaultDate = $("#"+elementId).val();
    let options = {
      format:"YYYY-MM-DDTHH:mm",
      sideBySide: true,
      widgetPositioning:{
        vertical: "bottom",
        horizontal: "left"
      },
    };
    if(minDate){
      options.minDate = minDate;
    }
    if(maxDate){
      options.maxDate = maxDate;
    }
    $("#"+elementId).attr("autocomplete", "false");
    $("#"+elementId).datetimepicker(options);
    if(defaultDate!=""){
      $("#"+elementId).val(defaultDate);
    }
  },

  setupDatePicker(elementId, minDate=null, maxDate=null){
    let defaultDate = $("#"+elementId).val();
    let options = {
      format:"YYYY-MM-DD",
      widgetPositioning:{
        vertical: "bottom",
        horizontal: "left"
      },
    };
    if(minDate){
      options.minDate = minDate;
    }
    if(maxDate){
      options.maxDate = maxDate;
    }
    $("#"+elementId).attr("autocomplete", "false");
    $("#"+elementId).datetimepicker(options);
    if(defaultDate!=""){
      $("#"+elementId).val(defaultDate);
    }
  },

  validateUser() {
    let valid = true
    $("#user_details_username").removeClass("error-field")
    $("#user_details_password").removeClass("error-field")
    $("#user_details_name").removeClass("error-field")
    if ($("#user_details_username").val() == "" || $("#user_details_username").val() == undefined || $("#user_details_username").val() == null  ){
      $("#user_details_username").addClass("error-field")
      valid = false
      showFailureMessage("Invalid Username")
    }
    if (($("#user_details_password").val() == "" || $("#user_details_password").val() == undefined || $("#user_details_password").val() == null) && window.location.href.indexOf("edit") == -1 ){
      $("#user_details_password").addClass("error-field")
      valid = false
      showFailureMessage("Invalid Password")
    }
    if ($("#user_details_name").val() == "" || $("#user_details_name").val() == undefined || $("#user_details_name").val() == null  ){
      $("#user_details_name").addClass("error-field")
      valid = false
      showFailureMessage("Invalid Name")
    }
    return valid
  },

  viewMember(id) {
    window.location = "/members/" + id
  },

  validateMember() {
    let valid = true
    $("#member_details_name").removeClass("error-field")
    $("#member_details_phone_number").removeClass("error-field")
    $("#member_details_name_of_force").removeClass("error-field")
    $("#member_details_designation").removeClass("error-field")
    $("#member_details_force_ppo_pen_number").removeClass("error-field")

    if ($("#member_details_name").val() == "" || $("#member_details_name").val() == undefined || $("#member_details_name").val() == null  ){
      $("#member_details_name").addClass("error-field")
      valid = false
      showFailureMessage("Invalid Name")
    }
    if ($("#member_details_phone_number").val() == "" || $("#member_details_phone_number").val() == undefined || $("#member_details_phone_number").val() == null  ){
      $("#member_details_phone_number").addClass("error-field")
      valid = false
      showFailureMessage("Invalid Phone number")
    }
    if ($("#member_details_name_of_force").val() == "" || $("#member_details_name_of_force").val() == undefined || $("#member_details_name_of_force").val() == null  ){
      $("#member_details_name_of_force").addClass("error-field")
      valid = false
      showFailureMessage("Invalid Name of force")
    }
    if ($("#member_details_designation").val() == "" || $("#member_details_designation").val() == undefined || $("#member_details_designation").val() == null  ){
      $("#member_details_designation").addClass("error-field")
      valid = false
      showFailureMessage("Invalid Designation")
    }
    if ($("#member_details_force_ppo_pen_number").val() == "" || $("#member_details_force_ppo_pen_number").val() == undefined || $("#member_details_force_ppo_pen_number").val() == null  ){
      $("#member_details_force_ppo_pen_number").addClass("error-field")
      valid = false
      showFailureMessage("Invalid Registration number")
    }
    return valid
  },

  validateContactForm: function (mobile=false) {
    
      let validFlag = true;

      $("#name").removeClass("errorField");
      $("#email").removeClass("errorField");
      $("#phone").removeClass("errorField");

      if ($("#name").val() == undefined || $("#name").val() == null || $("#name").val().toString().trim() == "") {
        validFlag = false;
        $("#name").addClass("errorField");
      }

      if ($("#email").val() == undefined || $("#email").val() == null || $("#email").val().toString().trim() == "") {
        validFlag = false;
        $("#email").addClass("errorField");
      }
      if ($("#phone").val() == undefined || $("#phone").val() == null || $("#phone").val().toString().trim() == "") {
        validFlag = false;
        $("#phone").addClass("errorField");
      }

      if (!validFlag) {
        App.showFailureMessage("Fill in the mandatory fields");
        return false;
      } else {
        return true;
      }
    
  },

  validateAdmissionForm: function (mobile=false) {
    
    let validFlag = true;

    $("#pupil_name").removeClass("errorField");
    $("#pupil_dob").removeClass("errorField");
    $("#pupil_curr_school").removeClass("errorField");
    $("#name").removeClass("errorField");
    $("#relationship").removeClass("errorField");
    $("#email").removeClass("errorField");
    $("#phone").removeClass("errorField");
    $("#residential_address").removeClass("errorField");

    if ($("#pupil_name").val() == undefined || $("#pupil_name").val() == null || $("#pupil_name").val().toString().trim() == "") {
      validFlag = false;
      $("#pupil_name").addClass("errorField");
    }
    if ($("#pupil_dob").val() == undefined || $("#pupil_dob").val() == null || $("#pupil_dob").val().toString().trim() == "") {
      validFlag = false;
      $("#pupil_dob").addClass("errorField");
    }
    if ($("#student_class").val() == "plus_one") {
      if ($("#po_pupil_aadhaar").val() == undefined || $("#po_pupil_aadhaar").val() == null || $("#po_pupil_aadhaar").val().toString().trim() == "") {
        validFlag = false;
        $("#po_pupil_aadhaar").addClass("errorField");
      }
      if ($("#po_pupil_stream").val() == undefined || $("#po_pupil_stream").val() == null || $("#po_pupil_stream").val().toString().trim() == "") {
        validFlag = false;
        $("#po_pupil_stream").addClass("errorField");
      }
    } else {
      if ($("#pupil_class").val() == undefined || $("#pupil_class").val() == null || $("#pupil_class").val().toString().trim() == "") {
        validFlag = false;
        $("#pupil_class").addClass("errorField");
      }
    }
    if ($("#pupil_curr_school").val() == undefined || $("#pupil_curr_school").val() == null || $("#pupil_curr_school").val().toString().trim() == "") {
      validFlag = false;
      $("#pupil_curr_school").addClass("errorField");
    }
    if ($("#name").val() == undefined || $("#name").val() == null || $("#name").val().toString().trim() == "") {
      validFlag = false;
      $("#name").addClass("errorField");
    }
    if ($("#relationship").val() == undefined || $("#relationship").val() == null || $("#relationship").val().toString().trim() == "") {
      validFlag = false;
      $("#relationship").addClass("errorField");
    }
    if ($("#email").val() == undefined || $("#email").val() == null || $("#email").val().toString().trim() == "") {
      validFlag = false;
      $("#email").addClass("errorField");
    }
    if ($("#phone").val() == undefined || $("#phone").val() == null || $("#phone").val().toString().trim() == "") {
      validFlag = false;
      $("#phone").addClass("errorField");
    }
    if ($("#residential_address").val() == undefined || $("#residential_address").val() == null || $("#residential_address").val().toString().trim() == "") {
      validFlag = false;
      $("#residential_address").addClass("errorField");
    }

    if (!validFlag) {
      App.showFailureMessage("Fill in the mandatory fields");
      return false;
    } else {
      return true;
    }
  
  },
  
  updateAdmissionForm(value){
    document.querySelector("#student_class").value = value
    if (value != "other") {
      document.querySelector(".admission-other").classList.add("hide")
			document.querySelector(".admission-plus-one").classList.remove("hide")
		} else {
      document.querySelector(".admission-other").classList.remove("hide")
			document.querySelector(".admission-plus-one").classList.add("hide")
		}
	},
  
  cancelAction() {
    window.history.go(-1)
  },
  
};

export default App